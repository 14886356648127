import axios from "axios"
import { navigate } from "gatsby"

const baseUrl = 'https://cms-minhelet.eec.co.il'

const registration = storeAPI => next => action => {

  //console.log('action type:', action.type)

  next(action)

  if (action.type === 'registration/init') {
    post(storeAPI.dispatch, action.payload)
  }

  if (action.type === 'registration/init-success') {
    navigate('/register/?success')
  }

}



const post = (dispatch, data) => {

  /* const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + jwt
  } */


  const payload = data


  axios.post(
    baseUrl + '/user/register?_format=json',
    payload,
    //{ headers: headers }
  )
    .then(res => {
      console.log(res.data);
      dispatch({
        type: 'registration/init-success',
        payload: {},
      })

    })
    .catch(error => {

      console.log(error)

      let message = ''

      if (error.response !== undefined) {
        console.log('response', error.response)

        message = error.response.data.message

      } else if (error.request !== undefined) {
        console.log('request', error.request)
      } else {
        console.log(error)
      }

      dispatch({
        type: 'registration/init-failure',
        payload: { message: message },
      })

      //dispatch({type:'error', payload:{error:error }})
    })

}


export { registration }