import axios from "axios"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas"
//import jwt_decode from "jwt-decode";
//import { ActionTypes } from 'react-redux'

const baseUrl = 'https://cms-minhelet.eec.co.il'


const pdf = storeAPI => next => action => {

  const state = storeAPI.getState()
  const dispatch = storeAPI.dispatch

  next(action)

  const actions = [
    'calculator/change-usage',
    'calculator/change-open',
    'calculator/add-usage',
    'calculator/remove-usage',
    'calculator/remove-open',
    'calculator/add-open-usage',
    'calculator/change-usage',
    'calculator/change-open'
  ]

  if (actions.includes(action.type) ) {
    dispatch({ type: 'calculator/summary-update', payload: {} })
  }

  //console.log('pdf middlewear action: ', action)
  const jwt = state.user.jwt


  if (action.type === 'pdf/recive') {
    openInTab(action.payload.data.url)
    dispatch({ type: 'pdf/reset', payload: {} })
  }

  if (action.type === 'pdf/data') {
    sendUserData(dispatch, state.user, action.payload )
  }





  if (action.type === 'error') {
    console.log('err')
    //navigate('/contact?success')
    //dispatch({ type: 'contact/reset', payload: {} })

    // check the error if it is token expirered then fire user/token expired
  }
}

const openInTab = (url) => {

  const link = document.createElement('a');

  link.href = url;
  link.target = '__blank'
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

}



const sendUserData = (dispatch, user, payload  ) => {

  const headers = {
    headers: {
      //'Accept': 'application/vnd.api+json',
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + user.jwt
    }
  }

  const url = baseUrl + '/api/unita-calc-pdf-data'

  
  const d = new Date()

  let ye = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  const date = `${da}/${mo}/${ye}`


  const raw = {
    title: user.user.full_name + ' ' + date,
    selected_uses:[
      ...payload.usage, 
      ...payload.open.map(e=>({...e,label:e.label+ ' -פתוח'}))
    ].map(item => ({
      title: item.label,
      planned: item.area,
      cost: item.cost,
      revenue: item.revenue,
    })),
    selected_plots: payload.plots.map(item => ({
      block: item.block,
      plot: item.plot
    })),
    compound: payload.compoundId,
    area: payload.summary.area_plots,
    building_rights_remaining: payload.summary.left,
    building_rights_used: payload.summary.area,
    total_expected_cost: payload.summary.cost,
    total_expected_income:payload.summary.income,
    expected_gross_profit:payload.summary.profit, 
  }

  //console.log(raw)
  //return;
  const data = JSON.stringify(raw)

  axios.post(url, data, headers)
    .then(response => {
      const pdfurl = response.data
      dispatch({
        type: 'pdf/recive',
        payload: { data: {url:pdfurl} }
      })
    })
    .catch(error => {

      console.log(error)
      dispatch({
        type: 'pdf/error',
        payload: {
          message: error.message
        }
      })
      

    })

}



export { pdf }