import { createStore as reduxCreateStore, applyMiddleware, combineReducers } from "redux"
import { loadState, saveState } from './localStorage';

import user from './reducers/user';
import registration from './reducers/registration';
import history from './reducers/history';
import contact from './reducers/contact';
import calculator from './reducers/calculator';
import pdf from './reducers/pdf';
import recoverPassword from './reducers/recover-password';
import rova from './reducers/rova';

import { app } from '../state/middleware/app';
import { auth } from '../state/middleware/auth';
import { registration as registrationMW } from '../state/middleware/registration';
import { pdf as pdfMW } from '../state/middleware/pdf';
import { recover } from '../state/middleware/recover';

const persistedState = loadState();

const middlewareEnhancer = applyMiddleware(app, auth, registrationMW, pdfMW, recover)

const rootReducer = combineReducers({ user, registration, history, contact, calculator, pdf, recoverPassword, rova });

const createStore = () => reduxCreateStore(rootReducer, persistedState, middlewareEnhancer)

const store = createStore()

store.subscribe(() => {
  saveState(store.getState());
});

//store.dispatch({ type: 'init', payload: {} })

export default store