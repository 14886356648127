const initialState = {

  email: '',

  phase: 'init',
  //token: '',
  //password: '',

  /*
    loading: false,
    messages:[],
   */

  init: {
    loading: false,
    messages: [],
  },
  set: {
    loading: false,
    messages: [],
  },
};


const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'recover/init':
      return { ...state, email: action.payload.email, phase: "init", init: { loading: true, messages: [] } };
    case 'recover/init-success':
      return { ...state, phase: "set", init: { loading: false, messages: [] } };
    case 'recover/init-error':
      console.log(action)
      return { ...state, init: { loading: false, messages: [action.payload.message] } };

    case 'recover/set':
      console.log(action)
      return { ...state, init: { loading: false, messages: [] }, set: { loading: true, messages: [] } };

    case 'recover/set-success':
      return { ...state, phase: "success", set: { loading: false, messages: [] } };

    case 'recover/set-error':
      return { ...state, set: { loading: false, messages: [action.payload.message] } };

    case 'recover/reset':
      return { ...initialState };

    default:
      return state;
  }
};

export default Reducer

