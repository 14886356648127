import axios from "axios"
const baseUrl = 'https://cms-minhelet.eec.co.il'

const recover = storeAPI => next => action => {

  //const state = storeAPI.getState()
  const dispatch = storeAPI.dispatch

  next(action)

  if (action.type === 'recover/init') {
    sendInitMessage(dispatch, action.payload)
  }

  if (action.type === 'recover/set') {
    sendSetMessage(dispatch, action.payload)
  }

  if (action.type === 'error') {
    console.log('err')
    //navigate('/contact?success')
    //dispatch({ type: 'contact/reset', payload: {} })

    // check the error if it is token expirered then fire user/token expired
  }
}

const sendInitMessage = (dispatch, payload) => {

  const { email } = payload

  const headers = {
    headers: {
      'Accept': 'application/vnd.api+json',
    }
  }

  const url = baseUrl + '/api/unita-forget-password'

  axios.post(url, { username_or_email: email }, headers)
    .then(response => {
      //console.log(response)
      dispatch({ type: 'recover/init-success', payload: {} })
    })
    .catch(error => {
      console.log('error M<M<', error)

      if (error.message === 'Request failed with status code 400') {
        dispatch({ type: 'recover/init-success', payload: {} })
      } else {
        dispatch({ type: 'recover/init-error', payload: { message: error.message } })
      }
    })

}

const sendSetMessage = (dispatch, payload) => {

  const headers = {
    headers: {
      'Accept': 'application/vnd.api+json',
    }
  }

  const url = baseUrl + '/api/unita-reset-password'

  axios.post(url, payload, headers)
    .then(response => {
      //console.log(response)
      dispatch({ type: 'recover/set-success', payload: {} })
    })
    .catch(error => {
      console.log('error M<M<', error)

      dispatch({ type: 'recover/set-error', payload: { message: error.message } })
    })

}


export { recover }