const initialState = {
  orders: [],
  loaded: false,
  loading: false,
  message: '',
};



const Reducer = (state = initialState, action) => {
  //console.log(action)

  switch (action.type) {
    case 'history/load':
      return { ...state, loading: true, loaded: false, message: '' };


    case 'history/success':
      return { ...state, loading: false, loaded: true, orders: action.payload.orders };

    case 'history/error':
      return { ...state, loading: false, loaded: false, orders: [] };


    case 'user/logout':
      return { ...state, loading: false, loaded: false, message: '' };

    case 'user/expired-token':
      return { ...state, loading: false, loaded: false, message: '' }

    default:
      return state;
  }
};

export default Reducer