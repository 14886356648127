const v = 'eilat-the-open-district-v1.5'

const old = [
  'eilat-the-open-district-v0.0',
  'eilat-the-open-district-v0.1',
  'eilat-the-open-district-v1.0',
  'eilat-the-open-district-v1.1',
  'eilat-the-open-district-v1.2',
  'eilat-the-open-district-v1.3',
  'eilat-the-open-district-v1.4',
]


if (typeof window !== 'undefined') {
  old.forEach(k => {
    localStorage.removeItem(k)
  })
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(v);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};


export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(v, serializedState);
  } catch {
    console.log('saveState error')
  }
};