import axios from "axios"
//import { navigate/* , useLocation */ } from "@reach/router"
import { navigate } from "gatsby"
//import CalcualtorData from './../../data-calculator/calculator.json';
//import { endPoint } from "../config"
//import jwt_decode from "jwt-decode";
//import { useProducts } from '../../data/products.js';
//import { ActionTypes } from 'react-redux'

const baseUrl = 'https://cms-minhelet.eec.co.il'


const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

//returns false ot the blcokID
const isSingleBlock = (plots) => {

  const blocks = plots.map(b => b.block).filter(onlyUnique)
  //console.log(blocks)

  if (blocks.length === 1) {
    return blocks[0]
  }

  return false
}

const app = storeAPI => next => action => {

  const state = storeAPI.getState()
  const dispatch = storeAPI.dispatch

  // in the case of adding new plot - if there is only one gush then select it already
  //case 'calculator/reset'
  //case 'calculator/add-plot-input'

  next(action)

  // auto select blcok if only one option
  if (action.type === 'calculator/reset' && action.payload.compoundId !== false) {

    let isSingleB = isSingleBlock(action.payload.compound.plots)
    if (isSingleB !== false) {
      dispatch({
        type: 'calculator/set-block',
        payload: {
          id: action.payload.plots[0].id,
          value: isSingleB
        }
      })
    }

  }

  // auto select blcok if only one option
  if (action.type === 'calculator/add-plot-input') {

    let isSingleB = isSingleBlock(state.calculator.compound.plots)
    if (isSingleB !== false) {
      dispatch({
        type: 'calculator/set-block',
        payload: {
          id: action.payload.id,
          value: isSingleB
        }
      })
    }

  }



  if (action.type === 'user/login-success') {

    // check if the is a need to load the calculator data
    // if loaded === false or loaded === true and date < user.data_date

    //console.log('state.calculator.date', state.calculator.date)
    ///console.log('state.user.jsonDate', state.user.jsonDate)

    if (state.calculator.loaded === false || state.calculator.date !== state.user.jsonDate) {
      //console.log('load calculator data')
      dispatch({ type: "calculator/load", payload: { date: state.user.jsonDate } })
    } else {

    }

  }

  if (action.type === 'calculator/load') {
    const jwt = state.user.jwt
    fetchCalculatorData(dispatch, jwt, action.payload)
  }


  if (action.type === 'contact/send') {
    sendContactMessage(dispatch, action.payload)
  }

  if (action.type === 'contact/success') {

    // wait 3 seconds and reset
    switch (action.payload.id) {
      case 'rova':
        navigate('/הרובע-הפתוח/דברו-איתנו?success')
        break;
      case 'minhelet':
        navigate('/צור-קשר?success')
        break;
      case 'event-registration':
        navigate('/event-registration?success')
        break;
      default:
        break;
    }

    dispatch({ type: 'contact/reset', payload: {} })

  }


  if (action.type === 'error') {
    console.log('err')
    //navigate('/contact?success')
    //dispatch({ type: 'contact/reset', payload: {} })

    // check the error if it is token expirered then fire user/token expired
  }
}


const fetchCalculatorData = (dispatch, jwt, payload) => {

  //console.log(dispatch, jwt)

  const headers = {
    headers: {
      //'Accept': 'application/vnd.api+json',
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + jwt
    }
  }


  const url = baseUrl + '/api/unita-calculator-json'

  // do this only if we have user
  axios.get(url, headers)
    .then(response => {
      //console.log(response.data)
      const json = JSON.parse(response.data)
      console.log('json', json)
      dispatch({
        type: 'calculator/load/success',
        payload: {
          compounds: json.compounds,
          yeodim: json.yeodim,
          date: payload.date
        }
      })
    })
    .catch(error => {
      //setLoading(false)
      //setMessage(error.message)

      dispatch({
        type: 'calculator/load/failure',
        payload: {
          message: error.message
        }
      })

    })



}

const sendContactMessage = (dispatch, payload) => {

  const { id, data } = payload
  //console.log(id, data)

  const headers = {
    headers: {
      'Accept': 'application/vnd.api+json',
    }
  }

  const url = baseUrl + '/entity/contact_message'

  axios.post(url, data, headers)
    .then(response => {
      //console.log(response)
      dispatch({ type: 'contact/success', payload: { id: id } })
    })
    .catch(error => {
      console.log('error M<M<', error)
      dispatch({ type: 'contact/error', payload: { id: id, message: error.message } })
    })

}

export { app }