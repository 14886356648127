const initialState = {

  submitting: false,
  messages: [],

};


const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'registration/init':
      return { ...state, submitting: true, messages: [] };
    case 'registration/init-success':
      return { ...state, submitting: false, messages: [] };
    case 'registration/init-failure':
      return { ...state, submitting: false, messages: [action.payload.message] };
    case 'registration/reset':
      return { ...state, submitting: false, messages: [] };

    default:
      return state;
  }
};

export default Reducer

