const initialState = {

  forms: [
    {
      id: 'rova',
      success: false,
      loading: false,
      error: '',
    },
    {
      id: 'minhelet',
      success: false,
      loading: false,
      error: '',
    },
    {
      id: 'event-registration',
      success: false,
      loading: false,
      error: '',
    },
  ]

};



const Reducer = (state = initialState, action) => {
  //console.log(action)

  switch (action.type) {

    case 'contact/send':
      return { ...state, forms: state.forms.map(e => e.id === action.payload.id ? { ...e, loading: true, error: '' } : { ...e }) };

    case 'contact/success':
      return { ...state, forms: state.forms.map(e => e.id === action.payload.id ? { ...e, success: true, loading: false } : { ...e }) };

    case 'contact/error':
      return { ...state, forms: state.forms.map(e => e.id === action.payload.id ? { ...e, success: false, loading: false, error: action.payload.message } : { ...e }) };

    case 'contact/reset':
      return { ...initialState };

    default:
      return state;
  }
};

export default Reducer