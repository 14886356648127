import './src/styles/global.css'
import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

// export const onRouteUpdate = ({ location }) => {
//   if (process.env.NODE_ENV !== 'production') {
//     return null;
//   }

//   const pagePath = location ? location.pathname + location.search + location.hash : undefined;
//   setTimeout(() => {
//     console.log(typeof gtag)
//     if (typeof window !== 'undefined' && typeof gtag === 'function') {
//       gtag('event', 'page_view', { page_path: pagePath });
//     }
//   }, 100);
//};
