import axios from "axios"
import { navigate } from "gatsby"
import jwt_decode from "jwt-decode";

const baseUrl = 'https://cms-minhelet.eec.co.il'

const auth = storeAPI => next => action => {

  const state = storeAPI.getState()
  const dispatch = storeAPI.dispatch

  const { user } = state

  if (action.type === 'token/check') {
    //console.log('token/check')
    if (user.jwt) {

      var decoded = jwt_decode(user.jwt);

      var exp = new Date(decoded.exp * 1000)
      var now = new Date()

      if (now > exp) {
        console.log('should log out')
        //console.log('exp:', exp.toISOString())
        //console.log('now:', now.toISOString())

        return dispatch({ type: 'user/expired-token', payload: {} })

      }
    }

    return
  }

  /* if (action.type !== 'user/expired-token' && user.jwt) {

    var decoded = jwt_decode(user.jwt);

    var exp = new Date(decoded.exp * 1000)
    var now = new Date()

    if (now > exp) {
      ('should log out')
      //console.log('exp:', exp.toISOString())
      //console.log('now:', now.toISOString())

      return dispatch({ type: 'user/expired-token', payload: {} })

    }

  } */

  next(action)


  if (action.type === 'user/login') {
    login(storeAPI.dispatch, action.payload)
  }


  if (action.type === 'user/login-success') {

    const destination = localStorage.getItem('destination')
    //console.log('user/login-success', destination)
    if (destination) {
      localStorage.removeItem('destination')
      navigate(destination)
    } else {
      //navigate('/הרובע-הפתוח/מחשבון-פלוס/')
      navigate('/הרובע-הפתוח')
      //navigate('/user')
    }

  }

  if (action.type === 'user/logout') {
    navigate('/login')
  }

}


const login = (dispatch, payload) => {

  const endpoint = baseUrl + '/api/unita-user-login?_format=json'
  const data = payload
  axios.post(endpoint, data)
    .then(response => {
      dispatch({
        type: 'user/login-success',
        payload: response.data
      })
    }).catch(error => {


      //setLoading(false)
      //setErrorMsg('שם משתמש או סיסמה אינם נכונים')
      console.log('error', error)
      console.log('error', error.nessage)

      let message = ''
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        message = error.response.data.message
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        message = 'request error';
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        message = error.message
      }

      dispatch({
        type: 'user/login-error',
        payload: { error: error, message: message }
      })

    })



}
export { auth }