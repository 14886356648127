exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-event-registration-js": () => import("./../../../src/pages/event-registration.js" /* webpackChunkName: "component---src-pages-event-registration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-recover-password-js": () => import("./../../../src/pages/recover-password.js" /* webpackChunkName: "component---src-pages-recover-password-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-user-js": () => import("./../../../src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */),
  "component---src-pages-הרובע-הפתוח-js": () => import("./../../../src/pages/הרובע-הפתוח.js" /* webpackChunkName: "component---src-pages-הרובע-הפתוח-js" */),
  "component---src-pages-הרובע-הפתוח-דברו-איתנו-js": () => import("./../../../src/pages/הרובע-הפתוח/דברו-איתנו.js" /* webpackChunkName: "component---src-pages-הרובע-הפתוח-דברו-איתנו-js" */),
  "component---src-pages-הרובע-הפתוח-מחשבון-פלוס-js": () => import("./../../../src/pages/הרובע-הפתוח/מחשבון-פלוס.js" /* webpackChunkName: "component---src-pages-הרובע-הפתוח-מחשבון-פלוס-js" */),
  "component---src-pages-צור-קשר-js": () => import("./../../../src/pages/צור-קשר.js" /* webpackChunkName: "component---src-pages-צור-קשר-js" */),
  "component---src-templates-basic-1-js": () => import("./../../../src/templates/basic1.js" /* webpackChunkName: "component---src-templates-basic-1-js" */),
  "component---src-templates-basic-2-js": () => import("./../../../src/templates/basic2.js" /* webpackChunkName: "component---src-templates-basic-2-js" */),
  "component---src-templates-inner-page-minhelet-js": () => import("./../../../src/templates/inner-page-minhelet.js" /* webpackChunkName: "component---src-templates-inner-page-minhelet-js" */),
  "component---src-templates-micrazim-lobby-js": () => import("./../../../src/templates/micrazim-lobby.js" /* webpackChunkName: "component---src-templates-micrazim-lobby-js" */),
  "component---src-templates-news-lobby-js": () => import("./../../../src/templates/news-lobby.js" /* webpackChunkName: "component---src-templates-news-lobby-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

