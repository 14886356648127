//import ShortUniqueId from 'short-unique-id'
//const uqid = new ShortUniqueId({ length: 10 })

const initialState = {

  compounds: [],
  yeodim: [],

  // load calculator data
  loading: false,
  loaded: false,
  date: '',
  message: '', //error

  /* download: {
    url: '',
    loading: false,
    loaded: false,
    message: '',
  }, */

  compound: false,
  compoundId: false,
  plots: [],
  usage: [],
  open: [],

  summary: {
    area_plots:0,
    area:0,
    left:0,
    rights:0,
    cost:0,
    income:0,
    profit:0
  }
  
};

const Reducer = (state = initialState, action) => {
  //console.log(action)

  switch (action.type) {
    case 'calculator/load-data': {
      return { ...state, compounds: action.payload.compounds, yeodim: action.payload.yeodim }
    }
    case 'calculator/load': {
      return { ...state, loading: true, message: '' }
    }
    case 'calculator/load/success': {
      //console.log(action.payload)
      return { ...state, loading: false, loaded: true, compounds: action.payload.compounds, yeodim: action.payload.yeodim, date: action.payload.date }
    }
    case 'calculator/load/failure': {
      return { ...state, loading: false, message: action.payload.message }
    }
    case 'calculator/reset': {
      // console.log(state)
      return { ...state, compoundId: action.payload.compoundId, compound: action.payload.compound, plots: action.payload.plots, usage: [], open: [] }
    }
    case 'calculator/add-plot-input': {
      return { ...state, plots: [...state.plots, action.payload] }
    }
    case 'calculator/remove-block-plot-input': {
      return { ...state, plots: state.plots.filter(p => p.id !== action.payload.id), usage: [], open: [] }
    }
    case 'calculator/set-block-and-plot': {
      //console.log(action)
      return { ...state, plots: state.plots.map(p => p.id === action.payload.id ? { ...p, block: action.payload.block, plot: action.payload.plot } : p) }
    }
    case 'calculator/set-block': {
      return { ...state, plots: state.plots.map(p => p.id === action.payload.id ? { ...p, block: action.payload.value, plot: '' } : p), usage: [], open: [] }
    }
    case 'calculator/set-plot': {
      return { ...state, plots: state.plots.map(p => p.id === action.payload.id ? { ...p, plot: action.payload.value } : p), usage: [], open: [] }
    }
    case 'calculator/add-usage': {
      return { ...state, usage: [...state.usage, action.payload] }
    }
    case 'calculator/remove-usage': {
      return { ...state, usage: state.usage.filter(u => u.id !== action.payload.id) }
    }
    case 'calculator/remove-open': {
      return { ...state, open: state.open.filter(u => u.id !== action.payload.id) }
    }
    case 'calculator/add-open-usage': {
      return { ...state, open: [...state.open, action.payload] }
    }
    case 'calculator/change-usage': 
      return { ...state, usage: state.usage.map(u => u.id === action.payload.id ? { ...u, ...action.payload.value } : u) }
    
    case 'calculator/change-open': 
      return { ...state, open: state.open.map(u => u.id === action.payload.id ? { ...u, ...action.payload.value } : u) }
    
    case 'calculator/summary-update': 
      return {...state, summary: summary(state) }
      
    /* case 'pdf/reset': {
      return { ...state, download: { url: '', loading: false, loaded: false, message: '' } }
    }
    case 'pdf/send': {
      return { ...state, download: { url: '', loading: true, loaded: false, message: '' } }
    }
    case 'pdf/recive': {
      return { ...state, download: { url: action.payload.data.url, loading: false, loaded: true, message: '' } }
    }
    case 'pdf/error': {
      return { ...state, download: { loading: false, loaded: false, message: action.payload.message } }
    } */

    default:
      return state;
  }
};

const summary = (state) => {

  const result = {
    area_plots: 0,
    area:0,
    left:0,
    rights:0,
    cost:0,
    income:0,
    profit:0
  }

  const keys = state.plots.map(p => `${p.block} ${p.plot}`)
  const rows = state.compound.plots.filter(e => {
    const a = `${e.block} ${e.plot}`
    return keys.includes(a)
  })

  rows.map(r => {
    result.area_plots += parseFloat(r.area)
    result.rights += parseFloat(r.left)
    return null
  })
  result.left = result.rights
  
  const all = [...state.usage, ...state.open]
  all.forEach(e => {
    result.area += parseInt(e.area)
    result.cost += parseInt(e.area) * parseFloat(e.cost)
    result.income += parseInt(e.area) * parseFloat(e.revenue)
  });
  result.left -= result.area

  result.profit = result.income > 0 ? 100 * (result.income - result.cost) / result.income : 0

  return result
}

export default Reducer
