const initialState = {

  user: {
    reprots: []
  },
  jwt: '',
  isLoggedIn: false,
  jsonDate: '',

  messages: [],
  loading: false,
};


const Reducer = (state = initialState, action) => {
  //console.log(action.type)

  switch (action.type) {
    case 'user/login':
      return { ...state, messages: [], loading: true };
    case 'user/login-error':
      return { ...state, messages: [action.payload.message], loading: false };
    case 'user/login-success':
      return { ...state, ...action.payload, messages: [], isLoggedIn: true, loading: false };
    case 'user/logout':
      return { ...state, user: {}, jwt: '', isLoggedIn: false, messages: [], loading: false };

    case 'user/expired-token':
      return { ...initialState };
    case 'masquerade/masquerade-sccuess':
      return { ...initialState, ...action.payload.new_user, isLoggedIn: true, messages: [] };
    case 'masquerade/unmasquerade':
      return { ...initialState, ...action.payload.old_user, isLoggedIn: true, messages: [] };

    case 'pdf/recive':
      //console.log(state.user.reports, action.payload.data)
      return { ...state, user: { ...state.user, reports: [...state.user.reports, action.payload.data] } }

    default:
      return state;
  }
};

export default Reducer