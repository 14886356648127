const initialState = {
  id: "",
  show: false,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "rova/show":
      console.log(action)
      return { ...state, show: true  /*,  id: action.payload.id */  };

    case "rova/hide":
      return { ...state, show: false , id: "" };

    case "rova/id":
      return { ...state,  id: action.payload.id };

    default:
      return state;
  }
};

export default Reducer;
