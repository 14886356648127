
const initialState = {

  url: '',
  loading: false,
  loaded: false,
  message: '',
  showModal1: false,
  showModal2: false,
};

const Reducer = (state = initialState, action) => {
  //console.log(action)

  switch (action.type) {

    case 'pdf/reset':
      return { ...initialState }

    case 'pdf/showModal1':
      return { ...state, showModal1: true }

    case 'pdf/hideModal1':
      return { ...state, showModal1: false }

    case 'pdf/hideModal2':
      return { ...state, showModal2: false }

    case 'pdf/send':
      return { ...state, url: '', loading: true, loaded: false, message: '', showModal1: false, showModal2: true }

    case 'pdf/recive':
      return { ...state, url: action.payload.data.url, loading: false, loaded: true, showModal2: false }

    case 'pdf/error':
      return { ...state, loading: false, loaded: false, message: action.payload.message }

    case 'user/logout':
      return { ...state, url: '', loading: false, loaded: false, message: '', showModal1: false, showModal2: false }

    default:
      return state;
  }
};

export default Reducer